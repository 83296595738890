import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

interface SvgLightProps {
  orientation: "left" | "right";
  rootClass?: string;
}

export function SvgLight({ orientation, rootClass }: SvgLightProps) {
  const boxRef = useRef<HTMLDivElement>(null);
  const lightCircleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (boxRef.current && lightCircleRef.current) {
        const { left, top } = boxRef.current.getBoundingClientRect();
        const x = event.clientX - left - 75; // Ajuste do centro do círculo
        const y = event.clientY - top - 500; // Usa o valor atual de imageCount

        requestAnimationFrame(() => {
          lightCircleRef.current!.style.transform = `translate(${x}px, ${y}px)`;
        });
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      component="div"
      className={rootClass}
      sx={{
        position: "relative",
        overflow: "hidden",
        zIndex: 1,
        boxShadow: `
            inset 1000px 0 1000px -1000px #93BB04, inset -1000px 0 1000px -1000px #779704
        `,
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/banner/bg-hexagon-${
          orientation === "left" ? "dark" : "right-dark"
        }.png`}
        style={{
          position: "relative",
          zIndex: 1,
          width: "100%",
          height: "100%",
        }}
        alt=""
      />

      <div
        ref={lightCircleRef}
        style={{
          width: "150px",
          height: "150px",
          borderRadius: "50%",
          position: "absolute",
          pointerEvents: "none",
          background: "rgba(199, 253, 6)",
          boxShadow: `
                        0 0 30px rgba(199, 253, 6),
                        0 0 50px rgba(199, 253, 6),
                        0 0 70px rgba(199, 253, 6),
                        0 0 90px rgba(199, 253, 6)
                    `,
          transform: "translate(-75px, -75px)", // Inicia no centro do elemento
          zIndex: 0,
        }}
      />
    </Box>
  );
}
