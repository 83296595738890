import { Box, Container } from "@mui/material";
import style from "./style.module.css";
import Video from "../Video";
import { SvgLight } from "../../../components/SvgLight";

export function Header({ refs }: any) {
  return (
    <Box component={"section"} className={style["outer-box"]}>
      <Box
        sx={{
          maxHeight: "620px",
          height: "100%",
          position: "relative",
          width: " 100%",
        }}
        component="div"
        className={style["outer-box__container"]}
      >
        <Box className={style["back"]}>
          <SvgLight orientation="left" />
          <SvgLight orientation="right" />
        </Box>
        <Container
          maxWidth="lg"
          className={style["container"]}
          sx={{
            height: "100%",
          }}
        >
          <Box
            sx={{
              height: "100%",
            }}
          >
            <Video />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
