import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import style from "./style.module.css";
import target from "../../../assets/images/target.svg";
import eye from "../../../assets/images/eye.svg";
import principles from "../../../assets/images/principles.svg";

export function Values({ refs }: any) {
  return (
    <Box component={"section"} className={style["values"]}>
      <Container className={style["values__container"]}>
        <Card className={style["values__card"]}>
          <img src={target} alt="alvo" />
          <CardContent className={style["values__content"]}>
            <Typography
              component="h3"
              style={{ marginBottom: "clamp(3rem, 5cqi, 5rem)" }}
            >
              missão
            </Typography>
            <Typography component="p">
              Impactar vidas e negócios gerando os melhores resultados no
              mercado imobiliário.
            </Typography>
          </CardContent>
        </Card>

        <Card className={style["values__card"]}>
          <img src={eye} alt="olho" />
          <CardContent className={style["values__content"]}>
            <Typography
              component="h3"
              style={{ marginBottom: "clamp(3rem, 5cqi, 5rem)" }}
            >
              visão
            </Typography>
            <Typography component="p">
              Ser reconhecida como a maior força de vendas em lançamentos
              imobiliários e desenvolvimento de pessoas no Brasil.
            </Typography>
          </CardContent>
        </Card>

        <Card className={style["values__card"]}>
          <img src={principles} alt="valores e princípios" />
          <CardContent className={style["values__content"]}>
            <Typography component="h3">valores e príncipios</Typography>
            <Typography component="h4">ser sangue verde é:</Typography>
            <Box component="div" className={style["values__card-info"]}>
              <Typography component="p">
                <strong>Verdade na mesa:</strong> Somos transparentes e íntegros
                em todas as relações.
                <br /> <strong>Princípios Cristãos:</strong> Deus é o dono da
                Vext! <br />
                <strong>Foco do cliente:</strong> Praticamos empatia.
                <br /> <strong>Coração ensinável:</strong> Praticamos humildade
                e buscamos conhecimento.
              </Typography>
              <Typography component="p">
                <strong>Fábrica de esticar gente:</strong> Transformamos o
                potencial das pessoas em potência. <br />{" "}
                <strong>Resultados exponenciais:</strong> Geramos grandes
                resultados atingindo metas crescentes e consistentes. <br />
                <strong>Somos protagonistas da nossa história:</strong> Temos
                autorresponsabilidade.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
