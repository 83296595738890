"use client";

import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";

export default function SvgContainer({ imgHeight = 400 }: any) {
  const boxRef = useRef<HTMLDivElement>(null);
  const lightCircleRef = useRef<HTMLDivElement>(null);
  const imageCountRef = useRef(0); // Ref para armazenar o valor atual de imageCount
  const [imageCount, setImageCount] = useState(0);
  const imageHeight = imgHeight; // Altura máxima de cada imagem

  // Função para simular atraso com Promise
  const waitForReady = async (delay: number) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
  };

  // Lógica principal
  const initialize = async () => {
    // Espera um tempo antes de acessar o ref
    await waitForReady(100); // Aguarde 100ms (ou outro valor necessário)

    if (boxRef.current) {
      const containerHeight = boxRef.current.getBoundingClientRect().height;
      console.log("🚀 ~ initialize ~ containerHeight:", containerHeight);
      const count = Math.ceil(containerHeight / imageHeight);
      setImageCount(count);
      imageCountRef.current = count; // Atualiza o ref com o novo valor
    }
  };

  // Chama a inicialização assim que o componente é montado
  useLayoutEffect(() => {
    const calculateImageCount = () => {
      if (boxRef.current) {
        const containerHeight = boxRef.current.getBoundingClientRect().height;
        console.log("Container Height Calculated:", containerHeight);
        const count = Math.ceil(containerHeight / imageHeight);
        setImageCount(count);
        imageCountRef.current = count;
      }
    };

    // Atraso inicial para garantir que o layout está pronto
    const timeoutId = setTimeout(() => {
      calculateImageCount();
    }, 300);

    // Listener para redimensionamentos
    window.addEventListener("resize", calculateImageCount);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", calculateImageCount);
    };
  }, [imageHeight]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (boxRef.current && lightCircleRef.current) {
        const { left, top } = boxRef.current.getBoundingClientRect();
        const x = event.clientX - left - 75; // Ajuste do centro do círculo
        const y = event.clientY - top - 150 * imageCountRef.current; // Usa o valor atual de imageCount

        requestAnimationFrame(() => {
          lightCircleRef.current!.style.transform = `translate(${x}px, ${y}px)`;
        });
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      className="svgContainer"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        minHeight: "100%",
        background: "#fff",
        overflow: "hidden",
        zIndex: 1,
      }}
    >
      {/* Imagens esquerda */}
      {Array.from({ length: imageCount }, (_, index) => (
        <img
          key={`left-image-${index}`}
          src={`${process.env.PUBLIC_URL}/assets/images/banner/bg-hexagon.png`}
          alt={`Hexagono ${index + 1}`}
          style={{
            position: "absolute",
            height: `${imageHeight}px`, // Garantir altura consistente
            width: "auto",
            objectFit: "cover",
            top: `${index * imageHeight}px`,
            left: 0,
            zIndex: 1,
            transform: index % 2 === 1 ? "scaleY(-1)" : "none",
          }}
        />
      ))}

      {/* Imagens direita */}
      {Array.from({ length: imageCount }, (_, index) => (
        <img
          key={`right-image-${index}`}
          src={`${process.env.PUBLIC_URL}/assets/images/banner/bg-hexagon-right.png`}
          alt={`Hexagono ${index + 1}`}
          style={{
            position: "absolute",
            height: `${imageHeight}px`, // Garantir altura consistente
            width: "auto",
            objectFit: "cover",
            top: `${index * imageHeight}px`,
            right: 0,
            zIndex: 1,
            transform: index % 2 === 1 ? "scaleY(-1)" : "none",
          }}
        />
      ))}

      {/* Círculo de luz que segue o mouse */}
      <div
        ref={lightCircleRef}
        style={{
          width: "150px",
          height: "150px",
          borderRadius: "50%",
          position: "absolute",
          pointerEvents: "none",
          background: "rgba(199, 253, 6)",
          boxShadow: `
                        0 0 30px rgba(199, 253, 6),
                        0 0 50px rgba(199, 253, 6),
                        0 0 70px rgba(199, 253, 6),
                        0 0 90px rgba(199, 253, 6)
                    `,
          transform: "translate(-75px, -75px)", // Inicia no centro do elemento
          zIndex: 0,
        }}
      />
    </Box>
  );
}
